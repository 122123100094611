import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import GraphQLExample from '../../../components/GraphQLExample';
import basicCreateSignatureOrderExample from '../../../examples/createSignatureOrder/basic';
import removePreviousSignaturesExample from '../../../examples/createSignatureOrder/removePreviousSignatures';
import criiptoVerifyOptionsExample from '../../../examples/createSignatureOrder/criiptoVerifyOptions';
import evidenceValidationStagesExample from '../../../examples/createSignatureOrder/evidenceValidationStages';
import uiExample from '../../../examples/createSignatureOrder/ui';
import uniqueEvidenceKeyExample from '../../../examples/createSignatureOrder/uniqueEvidenceKey';
import displayDocumentIDExample from '../../../examples/createSignatureOrder/displayDocumentID';
import addSignatoryBasicExample from '../../../examples/addSignatory/basic';
import addSignatoryEvidenceValidationExample from '../../../examples/addSignatory/evidenceValidation';
import addSignatoryRoleExample from '../../../examples/addSignatory/role';
import addSignatoryDocumentsExample from '../../../examples/addSignatory/documents';
import addSignatoryPreapprovedExample from '../../../examples/addSignatory/preapproved';
import addSignatorySealPositionExample from '../../../examples/addSignatory/sealPosition';
import addSignatoriesExample from '../../../examples/addSignatories/basic';
import createSignatureAppearanceExample from '../../../examples/createSignatureOrder/signatureAppearance';
import addSignatoryHeaderLeftExample from '../../../examples/addSignatory/headerLeft';
import addSignatoryDisplayNameExample from '../../../examples/addSignatory/displayName';
import basicCloseSignatureOrderExample from '../../../examples/closeSignatureOrder/basic';
import retentionCloseSignatureOrderExample from '../../../examples/closeSignatureOrder/retention';
import * as closeSignatureOrderExample from '../../../examples/closeSignatureOrder.graphql';
import cancelSignatureOrderExample from '../../../examples/cancelSignatureOrder/basic';
import createDrawableExample from '../../../examples/createSignatureOrder/drawable';
import addSignatoryDrawableExample from '../../../examples/addSignatory/drawable';
import changeSignatoryDrawableExample from '../../../examples/changeSignatory/drawable';
import * as signActingAsExample from '../../../examples/signActingAs.graphql';
import compositeAllOfExample from '../../../examples/createSignatureOrder/composite-allOf';
import validateDocumentExample from '../../../examples/validateDocument/basic';
export const _frontmatter = {
  "product": "signatures",
  "category": "GraphQL",
  "sort": 0,
  "title": "Examples"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Creating a signature order`}</h2>

    <p>{`Creates a signature order containing PDF documents to be signed.`}</p>
    <GraphQLExample example={basicCreateSignatureOrderExample} mdxType="GraphQLExample" />
    <h3>{`Removing existing signatures`}</h3>

    <p>{`It is currently not possible to sign documents that have previously been signed, the documents will be rejected unless you use `}<inlineCode parentName="p">{`removePreviousSignatures`}</inlineCode>{`.`}</p>
    <GraphQLExample example={removePreviousSignaturesExample} mdxType="GraphQLExample" />
    <h3>{`Changing authentication options`}</h3>
    <p>{`You can change authentication methods displayed or disable popups by modifying the Criipto Verify evidence provider settings as such:`}</p>

    <GraphQLExample example={criiptoVerifyOptionsExample} mdxType="GraphQLExample" />
    <h3>{`Requiring evidence validation before viewing documents`}</h3>
    <p>{`If your documents contain PII (before being signed) you may wish to enforce that the signatory validates their identitiy before viewing the documents.`}</p>

    <GraphQLExample example={evidenceValidationStagesExample} mdxType="GraphQLExample" />
    <h3>{`UI Settings`}</h3>
    <p>{`Signatory redirect URI, language, logo and stylesheet can be configured via the `}<inlineCode parentName="p">{`ui`}</inlineCode>{` input object on `}<inlineCode parentName="p">{`createSignatureOrder`}</inlineCode>{`.`}</p>

    <GraphQLExample example={uiExample} mdxType="GraphQLExample" />
    <h3>{`Requiring unique eIDs for each signatory`}</h3>
    <p>{`If you do not wish signatories to be able to sign multiple times (i.e. not using a "role" system or just have issues with persons sharing eID).
you can configure the Criipto Verify evidence provider to enforce uniqueness via the `}<inlineCode parentName="p">{`uniqueEvidenceKey: 'sub'`}</inlineCode>{` setting.`}</p>

    <GraphQLExample example={uniqueEvidenceKeyExample} mdxType="GraphQLExample" />
    <h3>{`Document identifier watermarks`}</h3>
    <p>{`Will add a unique identifier for the document to the specified margin of each page. Useful when printing signed documents.`}</p>

    <GraphQLExample example={displayDocumentIDExample} mdxType="GraphQLExample" />
    <h2>{`Adding a signatory to a signature order`}</h2>
    <p>{`Signatories are the individuals you wish to have sign a specific `}<inlineCode parentName="p">{`signatureOrder`}</inlineCode>{`. Once created they will have a unique link that you can redirect users to.`}</p>

    <GraphQLExample example={addSignatoryBasicExample} mdxType="GraphQLExample" />
    <h3>{`Evidence validation`}</h3>
    <p>{`If you wish to validate the user signing you should use the `}<inlineCode parentName="p">{`evidenceValidation`}</inlineCode>{` input for `}<inlineCode parentName="p">{`addSignatory`}</inlineCode>{`.`}</p>
    <p>{`Evidence validation keys must match what will be found in the claims token when signing. You can inspect JWT Tokens issued by Criipto Verify to see the necessary keys.`}</p>
    <p>{`Evidence will be hashed before storage and compared when a user tries to sign. If the evidence does not match an error will be displayed to the user and they will be prompted to try again.`}</p>

    <GraphQLExample example={addSignatoryEvidenceValidationExample} mdxType="GraphQLExample" />
    <h3>{`Signing with a role`}</h3>
    <p>{`You can define a role for signatories, if you wish for them to sign in a specific role (if perhaps, signing multiple times with the same eID).`}</p>

    <GraphQLExample example={addSignatoryRoleExample} mdxType="GraphQLExample" />
    <h3>{`Document scoping`}</h3>
    <p>{`By using the `}<inlineCode parentName="p">{`documents`}</inlineCode>{` input array for `}<inlineCode parentName="p">{`addSignatory`}</inlineCode>{` you can limit what documents a signatory will be shown.`}</p>

    <GraphQLExample example={addSignatoryDocumentsExample} mdxType="GraphQLExample" />
    <h3>{`Preapproval`}</h3>
    <p>{`For scenarios where you are displaying the PDFs to the users in your own UI you can opt to create a signatory with the documents preapproved.`}</p>
    <p>{`By preapproving documents for a signatory they are immediately sent to eID login to sign and skip the frontend approval flow.`}</p>
    <p>{`When using preapproval document-scoping is automatically used so make sure that you pass all documents from the signature order to the signatory.`}</p>

    <GraphQLExample example={addSignatoryPreapprovedExample} mdxType="GraphQLExample" />
    <h3>{`Custom seal position`}</h3>
    <p>{`If you do not wish to use the default seal positions (placed in two columns on a blank page appended to the order) you can define a set of custom seal positions.`}</p>
    <p>{`When using custom seal positions, they must be defined for all signatories added to the order.`}</p>
    <p>{`The coordinates defined for custom seal positions use the PDF coordinate system (0,0 is bottom left).`}</p>

    <GraphQLExample example={addSignatorySealPositionExample} mdxType="GraphQLExample" />
    <h3>{`Multiple signatories`}</h3>
    <p>{`If you need to add signatories in bulk you can use the `}<inlineCode parentName="p">{`addSignatories`}</inlineCode>{` mutation.
`}<inlineCode parentName="p">{`addSignatories`}</inlineCode>{` supports a `}<inlineCode parentName="p">{`signatories`}</inlineCode>{` input array, where each array item supports the same input values as `}<inlineCode parentName="p">{`addSignatoryMutation`}</inlineCode>{`.`}</p>

    <GraphQLExample example={addSignatoriesExample} mdxType="GraphQLExample" />
    <h2>{`Signature Apperance`}</h2>
    <p>{`Signature apperance (inside the PDF/document) can be configured via the `}<inlineCode parentName="p">{`signatureAppearance`}</inlineCode>{` input object on `}<inlineCode parentName="p">{`createSignatureOrder`}</inlineCode>{`.`}</p>
    <p>{`The example below shows how you might configure it to show the `}<inlineCode parentName="p">{`social number / cpr number`}</inlineCode>{` instead of a GUID for any SE/DK/NO login while also accounting for simple and complex claim types.`}</p>
    <p>{`If dealing with a single eID (like Swedish BankID) and only simple claims you can configure it as simply `}<inlineCode parentName="p">{`identifierFromEvidence: ["ssn"]`}</inlineCode></p>

    <GraphQLExample example={createSignatureAppearanceExample} mdxType="GraphQLExample" />
    <h3>{`Seal timestamp`}</h3>
    <p>{`By default the seal renders a timestamp in the left side of the seal header.`}</p>
    <p>{`This can be customized (per signatory) to either override the timestamp format, or change the values displayed by using available claims.`}</p>
    <p>{`Below is an example of how to modify the timestamp formatting:`}</p>

    <GraphQLExample example={addSignatoryHeaderLeftExample} mdxType="GraphQLExample" />
    <h3>{`Seal display name`}</h3>
    <p>{`By default the seal renders a name via the standard JWT name claim or via drawable name input.`}</p>
    <p>{`This can be customized (per signatory) to either override the name completely, or build a new name syntax from available claims.`}</p>
    <p>{`Below is an example of how one might render a business name together with the username, and fallback to a non-business scenario.`}</p>
    <p>{`This feature currently ONLY works with CriiptoVerify/OIDC based signatures.`}</p>

    <GraphQLExample example={addSignatoryDisplayNameExample} mdxType="GraphQLExample" />
    <h2>{`Closing a signature order`}</h2>
    <p>{`When all signatories have signed the signature order you can close it to retrieve your signed PDFs.`}</p>
    <p>{`The PDFs are deleted from Criipto servers upon calling `}<inlineCode parentName="p">{`closeSignatureOrder`}</inlineCode>{`. (Unless you define the `}<inlineCode parentName="p">{`retainDocumentsForDays`}</inlineCode>{` setting.)`}</p>

    <GraphQLExample example={basicCloseSignatureOrderExample} mdxType="GraphQLExample" />
    <h3>{`With document retention`}</h3>
    <p>{`If you wish, you can ask Criipto to retain the documents for up to 7 days after closing the signature order:`}</p>

    <GraphQLExample example={retentionCloseSignatureOrderExample} mdxType="GraphQLExample" />
    <h3>{`Include signature information`}</h3>
    <p>{`When retrieving document blobs for a signature order, you can also request information about the signatures embedded in the document.`}</p>
    <p>{`For users signing with an eID, this will include the `}<inlineCode parentName="p">{`jwt`}</inlineCode>{` issued which can be validated against the also-returned `}<inlineCode parentName="p">{`jwks`}</inlineCode>{` and decoded to provide the underlying claims (like ssn or other eID claim values).`}</p>

    <GraphQLExample example={{
      ...closeSignatureOrderExample,
      query: closeSignatureOrderExample.signaturesQuery
    }} mdxType="GraphQLExample" />
    <h2>{`Cancel a signature order`}</h2>
    <p>{`If for any reason you no longer need a signature order you can abandon it by using `}<inlineCode parentName="p">{`cancelSignatureOrder`}</inlineCode></p>

    <GraphQLExample example={cancelSignatureOrderExample} mdxType="GraphQLExample" />
    <h2>{`Drawable signatures`}</h2>
    <h3>{`Creating a signature order with drawable enabled`}</h3>
    <p>{`Define `}<inlineCode parentName="p">{`evidenceProviders`}</inlineCode>{` input when using `}<inlineCode parentName="p">{`createSignatureOrder`}</inlineCode>{` to enable drawable signatures.`}</p>

    <GraphQLExample example={createDrawableExample} mdxType="GraphQLExample" />
    <h3>{`Adding a signatory with selectively enabled evidence provider`}</h3>
    <p>{`You can define `}<inlineCode parentName="p">{`evidenceProviders`}</inlineCode>{` with `}<inlineCode parentName="p">{`enabledByDefault: false`}</inlineCode>{` and then selectively enabling them using the `}<inlineCode parentName="p">{`evidenceProviders`}</inlineCode>{` array input for `}<inlineCode parentName="p">{`addSignatory`}</inlineCode>{`.`}</p>

    <GraphQLExample example={addSignatoryDrawableExample} mdxType="GraphQLExample" />
    <h3>{`Changing a signatory to selectively enable a evidence provider`}</h3>
    <p>{`You can define `}<inlineCode parentName="p">{`evidenceProviders`}</inlineCode>{` with `}<inlineCode parentName="p">{`enabledByDefault: false`}</inlineCode>{` and then selectively enabling them using the `}<inlineCode parentName="p">{`evidenceProviders`}</inlineCode>{` array input for `}<inlineCode parentName="p">{`changeSignatory`}</inlineCode>{`.`}</p>

    <GraphQLExample example={changeSignatoryDrawableExample} mdxType="GraphQLExample" />
    <h3>{`Sign acting as a signatory`}</h3>
    <p>{`The common case is to let signatories sign via their browser with an eID, this also provides the highest level of evidence.`}</p>
    <p>{`However if you wish to sign acting as a signatory, for example to automatically counter-sign documents with a specific image, you can use the `}<inlineCode parentName="p">{`signActingAs`}</inlineCode>{` mutation.`}</p>
    <p>{`Any signatory used `}<strong parentName="p">{`MUST be `}</strong><a parentName="p" {...{
        "href": "#preapproval"
      }}><strong parentName="a">{`preapproved`}</strong></a>{` as the API can no longer garantuee that the signatory has seen and approved the document.`}</p>

    <GraphQLExample example={signActingAsExample} mdxType="GraphQLExample" />
    <h3>{`Combining drawable with eID signing`}</h3>
    <p>{`In certain cases where signed documents need to be printed, it can be beneficial to have signatories sign both with an eID and a drawable signature.`}</p>
    <p>{`The API supports defining an `}<inlineCode parentName="p">{`allOf`}</inlineCode>{` evidence provider that is a composite of multiple other evidence providers. The order of the list defines the order the signatory is asked to sign in.`}</p>

    <GraphQLExample example={compositeAllOfExample} mdxType="GraphQLExample" />
    <h2>{`Validate document`}</h2>
    <p>{`If you wish to prevalidate that a document blob is valid, you can use the `}<inlineCode parentName="p">{`validateDocument`}</inlineCode>{` mutation.`}</p>

    <GraphQLExample example={validateDocumentExample} mdxType="GraphQLExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      